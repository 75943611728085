import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function SimpleAccordion({ items, openIdx, className, onExpand }) {
  const lists = items.map(({ title, description }, idx) => (
    <Item key={ title }
      title={ title }
      description={ description }
      idx={ idx + 1 }
      openIdx={ openIdx }
      className= { className }
      onExpand= { onExpand }
    />
  ))
  return (
    <div>
      { lists }
    </div>
  )
}

const Item = ({ title, description, idx, openIdx, className, onExpand }) => {
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (!openIdx) return
    if (idx != openIdx) return
    setExpanded(true)
  }, [openIdx])

  return (
    <Accordion className={ cn(className) } expanded={ expanded } onChange={ (event, isExpanded) => {
      if (event.target.onclick !== null) return
      if (onExpand) onExpand(idx, isExpanded)
      setExpanded(isExpanded)
    } }>
      <AccordionSummary
        className='font-bold md:text-lg'
        expandIcon={ <ExpandMoreIcon /> }
        aria-controls={ `${title}-content` }
        id={ `${title}-header` }
      >
        { title }
      </AccordionSummary>
      <AccordionDetails>
        { description }
      </AccordionDetails>
    </Accordion>
  )
}
